
import { mapGetters } from "vuex"
import Swiper from "swiper"
import "swiper/swiper.scss"

export default {
    name: "JobFilter",
    props: {
        jobCategory: {
            type: String,
        },
        swiper: {
            type: Boolean,
            default: false,
        },
        justify: {
            type: String,
            default: "center",
        },
    },
    data: () => ({ crafts: [] }),
    async mounted() {
        const allCategories = await this.$axios.$get("/api/job_categories")
        this.crafts = allCategories.data
        if (this.$breakpoint.smAndDown) {
            new Swiper(this.$refs["catSwiper"], {
                slidesPerView: "auto",
            })
        }
    },
    methods: {
        isCategoryActive(cat) {
            let filterCats = this.$route.query?.categories
            if (Array.isArray(filterCats)) {
                return filterCats.some((craft) => craft === cat)
            } else {
                return filterCats === cat
            }
        },
    },
    computed: {
        ...mapGetters("meta", ["meta"]),
        ...mapGetters("search", ["route"]),
    },
}
